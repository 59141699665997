import { promiseSetRecoil, promiseGetRecoil } from 'recoil-outside'
import { event_server } from 'Socket'
import { auth, checkToken } from 'api/backend'
import { usersState, environmentState } from 'state/atom'
import { removeDuplicateArr } from 'utils'

export const requestUsers = (offset, query = '') => {
  return new Promise(async (resolve, reject) => {
    try {
      // send inquiry to sockt for users
      const mode = await promiseGetRecoil(environmentState)
      const token = localStorage.getItem('auth_token')
      event_server.emit(
        'users',
        { status: 'get-data', mode, token, offset, query },
        async result => {
          console.log(' user sended successfully')
          result = JSON.parse(result)
          const { data } = result
          const users = await promiseGetRecoil(usersState)
          if (data?.length > 0) {
            const updatedList = removeDuplicateArr(
              [...users.list, ...data],
              (t, v) => t.Id === v.Id
            )
            await promiseSetRecoil(usersState, {
              ...users,
              list: updatedList,
              loading: false,
              userLoading: false,
            })
          } else {
            await promiseSetRecoil(usersState, {
              ...users,
              loading: false,
              userLoading: false,
            })
          }
          resolve(true)
        }
      )
    } catch (error) {
      reject(error)
    }
  })
}
export const requestUpdateUser = async user =>{
  return new Promise(async (resolve, reject) => {
    try {
        const mode = await promiseGetRecoil(environmentState)
        const token = localStorage.getItem('auth_token')
        event_server.emit(
            'users',
            {
                status: 'update',
                mode,
                token,
                data: user,
            },
            result => {
                console.log('user', result)
                result = JSON.parse(result)
                if(result.code === 200){
                  resolve(result.data)
                  return;
                }
                reject(result.data)
            }
        )
    } catch (error) {
        reject(error)
    }
  })
}
export const requestLicensesByOwner = async owner =>{
  const mode = await promiseGetRecoil(environmentState)
  const token = localStorage.getItem('auth_token')
  event_server.emit(
      'licenses-send',
      { status: 'get-license-by-owner', mode, token, query: owner },
      async result => {
          console.log('sended successfully')
          result = JSON.parse(result)
          const { data } = result
          const users = await promiseGetRecoil(usersState)
          if (data?.length > 0) {
              const updatedList = data
              await promiseSetRecoil(usersState, {
                  ...users,
                  licenses: updatedList,
              })
          } else {
              await promiseSetRecoil(usersState, {
                  ...users,
                  licenses:[],
              })
          }
      }
  )    
}
export const requestAuth = async creds => {
  return await auth(creds)
}

export const requestCheckToken = async () => {
  const token = localStorage.getItem('auth_token')
  if (token?.length > 0) {
    return await checkToken()
  }
  return {
    data: {
      authorized: false,
      exp: '',
      username: '',
    },
  }
}
export const requestClearLicenseOwner = async (license , owner) => {
  return new Promise(async (resolve, reject) => {
      try {
          const mode = await promiseGetRecoil(environmentState)
          const token = localStorage.getItem('auth_token')   
          event_server.emit("licenses-send",{
              status:"clear-license-owner",
              mode,
              token,
              data:{
                  license,
                  owner
              },
          },
          result => {
              try {
                  result = JSON.parse(result)
                  resolve(result.data)
                      
              } catch (error) {
                  reject(result)                        
              }
          })
      }
      catch (error) {
          reject(error)            
      }
  })
}
// request assign license to owner
export const requestAssignLicense = async (licenseType , owner) => {
  return new Promise(async (resolve, reject) => {
      try {
          const mode = await promiseGetRecoil(environmentState)
          const token = localStorage.getItem('auth_token')   
          event_server.emit("licenses-send",{
              status:"assign-license-owner",
              mode,
              token,
              data:{
                licenseType,
                  owner
              },
          },
          result => {
              try {
                  result = JSON.parse(result)
                  resolve(result.data)
                      
              } catch (error) {
                  reject(result)                        
              }
          })
      }
      catch (error) {
          reject(error)            
      }
  })
}