import { useState } from 'react'
import { Heading, VStack, HStack } from '@chakra-ui/layout'
import { format } from 'date-fns'
import {
  Input,
  Editable,
  EditablePreview,
  EditableInput,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  IconButton,
  Button,
  Spinner,
  Tooltip,
  Tag,
  useDisclosure
} from '@chakra-ui/react'
import EditableControls from 'components/partials/EditableControls'
import ResolveStatusModal from "components/pages/modals/resolve"
import { FiFilter } from 'react-icons/fi'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { requestUsersSession } from 'state/actions/session'

const LOADING = 1
const LOAD_IDLE = 0
const LOADED = 2
const USERS_LOAD_LIMIT = 5

export default function List({
  status,
  heading,
  isRewardEditable = true,
  displayUsers = true,
  hasStatusBox = false,
  hasError=false,
  statusBoxColor = 'white',
  isSendingRewards,
  session,
  sessionStatus,
  updateSessionStatus = () =>{},
  updateSession = () => { },
  resendRewards = () => { },
}) {
  const [query,setQuery] = useState("")
  const [resolveStatus,setResolveStatus] = useState(false)
  const [isSessionUpdating, setIsSessionUpdating] = useState(false)
  const [isUsersUpdateStatus, setIsUsersUpdateStatus] = useState(LOAD_IDLE)
  const [users, setUsers] = useState(null)
  const {
    isOpen: isResolveModalOpen,
    onOpen: onOpenResolveModal,
    onClose: onCloseResolveModal,
  } = useDisclosure()
  const startAt = format(
    new Date(session.StartAt * 1000),
    'M/dd/yyyy hh:mm:ss a'
  )
  const endAt = format(new Date(session.EndAt * 1000), 'M/dd/yyyy hh:mm:ss a')
  const handleUpdateSession = async session => {
    setIsSessionUpdating(true)
    // const updatedSession
    updateSession(session)
    setIsSessionUpdating(false)
  }
  const handleLoadUsers = async () => {
    if (isUsersUpdateStatus !== LOAD_IDLE) return
    setIsUsersUpdateStatus(LOADING)
    let offset = 0
    if (users) offset = users.length
    let _users = await requestUsersSession(session.Id, offset, USERS_LOAD_LIMIT,query)
    if (!_users) _users = []
    if (users) _users = [...users, ..._users]
    setUsers(_users)
    if (_users && _users.length < USERS_LOAD_LIMIT)
      setIsUsersUpdateStatus(LOADED)
    else setIsUsersUpdateStatus(LOAD_IDLE)
  }
  const handleQueryChange = e => {
    setQuery(e.target.value.trim())
  }
  const handleKeyPressed = e => {
    if (e.key === 'Enter') {
      setUsers(null)
      setIsUsersUpdateStatus(LOAD_IDLE)
      handleLoadUsers()
    }
  }  
  const handleResendRewards=()=>{
    resendRewards(session).then(response=>{
      const { code, data}=response
      if(code !== 200){
        updateSessionStatus(data)    
        console.log(data)    
        const { Status } = data
        const sessionStatusMessage = sessionStatus[Status].message
        setResolveStatus(sessionStatusMessage)
        onOpenResolveModal(true)
        return
    }
    }) 
  }
  return (
    <AccordionItem>
      {({ isExpanded }) => {
        if (isExpanded && displayUsers && users === null) handleLoadUsers()
        return (
          <>
            <ResolveStatusModal status={resolveStatus}  isOpen={isResolveModalOpen} onClose={onCloseResolveModal}/>
            <h2>
              <AccordionButton _focus={{ borderColor: 'none' }}>
                <Box flex="1" textAlign="left">
                  {status}
                </Box>
                {hasStatusBox && <Box bg={statusBoxColor} w={5} h={5}></Box>}
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <VStack p={5} alignItems="center">
                  <Heading size="sm" color={hasError && 'red'}>
                    {heading}
                    {hasError && 
                    <Button size='sm' ml="2" color='black' 
                    onClick={handleResendRewards} isLoading={isSendingRewards}>
                      Resolve
                    </Button> }
                  </Heading>
                <VStack>
                  <p>Start At: {startAt}</p>
                  <p>End At: {endAt}</p>
                  <p>Session Id: {session.Id}</p>
                  <HStack>
                    <p marginbottom="0" marginleft="0">
                      Rewards
                    </p>
                    {isRewardEditable ? (
                      <Editable
                        defaultValue={session.AwardAmount?.toString()}
                        onSubmit={value => {
                          const updatedSession = {
                            ...session,
                            AwardAmount: parseFloat(value),
                          }
                          handleUpdateSession(updatedSession)
                        }}
                      >
                        <EditablePreview />
                        <span>
                          <EditableInput w="3vw" /> ELA
                        </span>
                        {isSessionUpdating ? (
                          <Spinner ml="2" size="xs" />
                        ) : (
                          <EditableControls />
                        )}
                      </Editable>
                    ) : (
                      <span>{session.AwardAmount} ELA</span>
                    )}
                  </HStack>
                </VStack>
              </VStack>
              {displayUsers && (
                <VStack p={3}>
                  <HStack>
                    <p>Users</p>
                    <Input placeholder="Search" onChange={handleQueryChange} onKeyPress={handleKeyPressed  } />
                    <IconButton size="sm" icon={<FiFilter />} />
                  </HStack>
                  <VStack>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Serial</Th>
                          <Th>Model</Th>
                          <Th>Percent</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {users?.map((user, index) => {
                          const sessionStatusMessage =
                            sessionStatus[user.Status].message
                          const hasStatusError = user.Status !== 0 && user.Status !== 2 && user.Status !== 8

                          return (
                            <Tr key={index}>
                              <Td>{user.Serial}</Td>
                              <Td>{user.Model}</Td>
                              <Td isNumeric>
                                <HStack>
                                  <span>{user.Percent.toFixed(2)}</span>
                                  {hasStatusError && <Tooltip label={sessionStatusMessage}>
                                    <Tag colorScheme="red">
                                      <AiOutlineExclamationCircle />
                                    </Tag>
                                  </Tooltip>}
                                </HStack>
                              </Td>
                            </Tr>
                          )
                        })}
                      </Tbody>
                      <TableCaption>
                        {isUsersUpdateStatus === LOADING && <Spinner />}
                        {isUsersUpdateStatus === LOAD_IDLE && (
                          <Button
                            size="sm"
                            variant="ghost"
                            onClick={event => handleLoadUsers()}
                          >
                            Load More
                          </Button>
                        )}
                        {isUsersUpdateStatus === LOADED &&
                          (!users || users.length === 0) &&
                          'EMPTY'}
                      </TableCaption>
                    </Table>
                  </VStack>
                </VStack>
              )}
            </AccordionPanel>
          </>
        )
      }}
    </AccordionItem>
  )
}
