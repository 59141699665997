import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { walletState } from 'state/atom'
import { requestWallet } from 'state/actions/wallet'
export default function useWallet() {
  const [wallet, setWallet] = useRecoilState(walletState)
  useEffect(() => {
    const processwallet = async () => setWallet(await requestWallet())
    processwallet()
  }, [setWallet])
  return { wallet }
}
