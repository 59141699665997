import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Heading,
} from '@chakra-ui/react'
export default function WalletTransactions({ wallet }) {
  return (
    <>
      <Heading>Transactions</Heading>
      <Table variant="simple">
        <TableCaption>History</TableCaption>
        <Thead>
          <Tr>
            <Th>Hash</Th>
            <Th isNumeric>Type</Th>
            <Th isNumeric>Amount</Th>
            <Th>Date</Th>
          </Tr>
        </Thead>
        <Tbody>
          {wallet.Transactions?.map((transaction, index) => {
            return (
              <Tr key={index}>
                <Td>{transaction.Txid}</Td>
                <Td isNumeric>{transaction.Type}</Td>
                <Td isNumeric>{transaction.Amount}</Td>
                <Td>{transaction.Date}</Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </>
  )
}
