import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { mainchainServerState } from 'state/atom'
import { event_server } from "Socket"
import { requestServerStatus, requestServerRestart, requestServerStart, requestServerStop, requestResyncServer } from 'state/actions/mainchain'
export default function useServerStatus() {
  const [server, setServer] = useRecoilState(mainchainServerState)
  const handleServerStart = async () => {
    requestServerStart()
  }
  const handleServerRestart = () => {
    requestServerRestart()
  }
  const handleServerStop = () => {
    requestServerStop()
  }
  const handleServerResync = () => {
    requestResyncServer()
  }
  const fetchServerStatus = () => {
    requestServerStatus()
  }
  useEffect(() => {
    event_server.on("mainchain", status => {
      setServer({
        ...status,
      })
    })
    fetchServerStatus()
    const intervalId = setInterval(fetchServerStatus, 10000)
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [])
  return { server, handleServerRestart, handleServerStop, handleServerStart, handleServerResync }
}
