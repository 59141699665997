import { createPortal } from 'react-dom'
import { VStack } from '@chakra-ui/layout'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import TransferModal from 'components/pages/modals/transfer'
export default function Device({ hasStatusBox, heading, device, isOpen, onClose }) {
  const {
    isOpen: isTransferModalOpen,
    onOpen: onOpenTransferModal,
    onClose: onCloseTransferModal,
  } = useDisclosure()
  return createPortal(
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Device Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack w="full" h="full" alignItems="flex-start">
            <Text>{heading}</Text>
            <Text>Status: {hasStatusBox ? 'Failed' : 'Success'}</Text>
            <Text>Serial #: {device?.Serial}</Text>
            <VStack w="full" h="full" alignItems="flex-end">
              <Button onClick={onOpenTransferModal}>Transfer Funds</Button>
              <TransferModal
                heading={'Transfer your funds to this address'}
                isOpen={isTransferModalOpen}
                onClose={onCloseTransferModal}
                address={device?.WalletAddress}
              />
            </VStack>
            <Tabs w="full" variant="enclosed" align="start" isLazy>
              <TabList>
                <Tab>Info</Tab>
                <Tab>Transactions</Tab>
                <Tab>Settings</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <VStack w="full" h="full" alignItems="flex-start">
                    <Text>License: {device?.Type}</Text>
                    <Text>License #: {device?.License}</Text>
                    <Text>Wallet Address: {device?.WalletAddress}</Text>
                    <Text>
                      Purchase Date:{' '}
                      {format(
                        new Date(device?.RegisteredDate * 1000),
                        'M-dd-yyyy'
                      )}
                    </Text>
                  </VStack>
                </TabPanel>
                <TabPanel>
                  <p>No mockups yet</p>
                </TabPanel>
                <TabPanel>
                  <p>No mockups yet</p>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        </ModalBody>
        <ModalFooter>
          {/* <Button colorScheme="blue" mr={3} onClick={onClose}>

          </Button>
          <Button variant="ghost">Secondary Action</Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>,
    document.getElementById('modal')
  )
}
