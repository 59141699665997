import { useState } from 'react'
import { HStack } from '@chakra-ui/layout'
import { Select, Button, useDisclosure, useToast, Input } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import LicenseModal from 'components/pages/modals/license'
export default function LicenseHeader({ generatedLicense, generateLicense }) {
  const [isGenerateButtonDisabled, setIsGenerateButtonDisabled] = useState(true)
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm()
  const {
    isOpen: isLicenseModalOpen,
    onOpen: onOpenLicenseModal,
    onClose: onCloseLicenseModal,
  } = useDisclosure()
  const toast = useToast()
  const handleLicenseTypeSelectChange = e => {
    const { target } = e
    if (target.value?.length > 0) {
      setIsGenerateButtonDisabled(false)
    } else {
      setIsGenerateButtonDisabled(true)
    }
  }
  const handleGenerateLicense = async data => {
    try {
      await generateLicense(data)
      onOpenLicenseModal()
    } catch (error) {
      toast({
        title: error,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })
    }
  }
  return (
    <form onSubmit={handleSubmit(handleGenerateLicense)}>
      <HStack p="5" mb={5}>
        <LicenseModal
          license={generatedLicense}
          isOpen={isLicenseModalOpen}
          onOpen={onOpenLicenseModal}
          onClose={onCloseLicenseModal}
        />
        <Select
          id="type"
          placeholder="Select License"
          {...register('license-type', {
            required: 'This is required',
          })}
          isInvalid={errors['license-type']}
          onChange={handleLicenseTypeSelectChange}
        >
          <option value="premium">Premium</option>
          <option value="regular">Regular</option>
        </Select>
        <Input 
        placeholder='Username'
        {...register('username', {
          required: 'This is required',
        })}        
        />
        <Button
          type="submit"
          pr="7"
          pl="7"          
          isLoading={isSubmitting}
          disabled={isGenerateButtonDisabled}
        >
          Generate
        </Button>
      </HStack>
    </form>
  )
}
