import { VStack, HStack } from '@chakra-ui/layout'
import { Divider, Spinner, Text } from '@chakra-ui/react'
import useWallet from 'hooks/useWallet'
import WalletHeader from 'components/pages/wallet/header'
import WalletTransactions from 'components/pages/wallet/transactions'
export default function Wallet() {
  const { wallet } = useWallet()
  const isLoading = wallet.Address?.length === 0
  if (wallet.Address === undefined) {
    return (
      <HStack w="full" h="80vh" p={10} spacing={5} justify="center">
        <Text>Mainchain is syncing. try again later.</Text>
      </HStack>
    )
  }
  if (isLoading) {
    return (
      <HStack w="full" h="80vh" p={10} spacing={5} justify="center">
        <Spinner size="xl" />
      </HStack>
    )
  }
  return (
    <VStack w="full" h="full" p={10} spacing={5} justify="center">
      {/* <Heading>Wallet</Heading> */}
      <WalletHeader wallet={wallet} />
      <Divider />
      <WalletTransactions wallet={wallet} />
    </VStack>
  )
}
