import { VStack, HStack } from '@chakra-ui/layout'
import { Input, Accordion, IconButton, Spinner } from '@chakra-ui/react'
import { FiFilter } from 'react-icons/fi'
import Controller from 'components/pages/rewards/controller'
import DevicesList from 'components/pages/rewards/devices/list'
import useDevice from 'hooks/useDevice'
export default function Devices() {
  const {
    devices,
    sessionStatus,
    pageLoading,
    deviceLoading,
    handleRequestDevices,
    query,
    setQuery,
  } = useDevice()
  const handleQueryChange = e => {
    setQuery(e.target.value.trim())
  }
  const handleKeyPressed = e => {
    if (e.key === 'Enter') {
      handleRequestDevices(true, query)
    }
  }
  if (pageLoading) {
    return (
      <Controller heading="Devices">
        <VStack w="full" h="55vh" alignItems="center" justify="center">
          <Spinner size="xl" />
        </VStack>
      </Controller>
    )
  }
  return (
    <Controller heading="Devices">
      <VStack w="full" h="full">
        <HStack>
          <p>Devices</p>
          <Input
            placeholder="Search"
            onChange={handleQueryChange}
            onKeyPress={handleKeyPressed}
          />
          <IconButton size="sm" icon={<FiFilter />} />
        </HStack>
        <Accordion w="full">
          {devices.list.map(device => {
            const heading = `${device.Serial} - ${device.Hardware}`
            if (device.Issue !== 0) {
              const hasError = device.Issue !== 2 && device.Issue !== 8
              const statusMessage = sessionStatus[device.Issue].message
              if (hasError) {
                return (
                  <DevicesList
                    key={device.Serial}
                    heading={heading}
                    device={device}
                    hasStatusBox
                    statusBoxColor="red"
                    issue={statusMessage}
                  />
                )
              }
            }
            return <DevicesList key={device.Serial} heading={heading} device={device} />
          })}
        </Accordion>
        {deviceLoading && <Spinner size="lg" />}
      </VStack>
    </Controller>
  )
}
