import { promiseGetRecoil } from 'recoil-outside'
import { event_server } from 'Socket'
import {
    environmentState,
} from 'state/atom'


export const requestSettings = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const mode = await promiseGetRecoil(environmentState)
            const token = localStorage.getItem('auth_token')
            event_server.emit(
                'config',
                { status: 'default-config', mode, token },
                result => {
                    console.log('settings', result)
                    result = JSON.parse(result)
                    resolve(result.data)
                }
            )
        } catch (error) {
            reject(error)
        }
    })
}
export const requestUpdateSettings = settings => {
    return new Promise(async (resolve, reject) => {
        try {
            const mode = await promiseGetRecoil(environmentState)
            const token = localStorage.getItem('auth_token')
            event_server.emit(
                'config',
                {
                    status: 'set-config',
                    mode,
                    token,
                    data: {
                        fee: settings['transfer-fee'],
                        interval: settings['interval'],
                        reward: settings['default-reward'],
                        enabled: settings['enabled'],
                    },
                },
                result => {
                    console.log('settings', result)
                    result = JSON.parse(result)
                    resolve(result.data)
                }
            )
        } catch (error) {
            reject(error)
        }
    })
}