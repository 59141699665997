import { createPortal } from 'react-dom'
import { VStack, HStack } from '@chakra-ui/layout'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
} from '@chakra-ui/react'
export default function ResolveModal({ status, isOpen, onClose }) {
  return createPortal(
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Resolve Status</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack w="full" h="full" alignItems="flex-start" >
            <HStack w="full" h="full" justify={"center"}>
                <Text color="red">{status}</Text>
            </HStack>
          </VStack>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </ModalContent>
    </Modal>,
    document.getElementById('modal')
  )
}
