import { createPortal } from 'react-dom'
import { VStack, HStack } from '@chakra-ui/layout'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  IconButton,
  useToast,
} from '@chakra-ui/react'
import { FaClipboard } from 'react-icons/fa'
import { copyToClipBoard } from 'utils'
export default function LicenseModal({  license, isOpen, onClose }) {
  const toast = useToast()
  const handleCopyLicenseToClipboard = () => {
    copyToClipBoard(license)
    toast({
      title: 'copied',
      status: 'success',
      duration: 2000,
      isClosable: true,
      position: 'top',
    })
  }
  return createPortal(
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Generated License</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack w="full" h="full" alignItems="flex-start" >
            <HStack w="full" h="full" justify={"center"}>
              <Text fontSize={"xl"}>{license}</Text>
              <IconButton
                variant="ghost"
                icon={<FaClipboard />}
                onClick={handleCopyLicenseToClipboard}
              />
            </HStack>
          </VStack>
        </ModalBody>
        <ModalFooter>
          {/* <Button colorScheme="blue" mr={3} onClick={onClose}>

          </Button>
          <Button variant="ghost">Secondary Action</Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>,
    document.getElementById('modal')
  )
}
