import { useEffect, useState } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { sessionStatusState, devicesState } from 'state/atom'
import useScroll from 'hooks/useScroll'
import { requestDevices } from 'state/actions/device'
export default function UseDevice() {
  const [query, setQuery] = useState('')
  const [devices, setDevices] = useRecoilState(devicesState)
  const sessionStatus = useRecoilValue(sessionStatusState)
  const { offset, setOffset } = useScroll()
  const pageLoading = devices?.loading
  const deviceLoading = devices?.devicesLoading
  const handleRequestDevices = async (clear = false) => {
    setOffset(0)
    setDevices({
      ...devices,
      list: clear ? [] : devices.list,
      devicesLoading: true,
    })

    await requestDevices(offset, query)
  }
  useEffect(() => {
    handleRequestDevices(false, query)
    // eslint-disable-next-line
  }, [offset])
  return {
    devices,
    sessionStatus,
    pageLoading,
    deviceLoading,
    handleRequestDevices,
    query,
    setQuery,
  }
}
