import { HStack } from '@chakra-ui/react'
import StatusDisplay from 'components/pages/rewards/dashboard/serverstatus/statusDisplay'
export default function ServerStatus({mainchainServer,rewardsServer,handleMainchainRestart,handleMainchainServerStart,
  handleMainchainStop,handleMainchainResync,handleRewardsStart,handleRewardsRestart,handleRewardsServerStop}) {
  return (
    <HStack h="full" alignItems="flex-start" justifyContent="space-between">
      <StatusDisplay
        server={mainchainServer}
        label="ELA Mainchain"
        handleServerStart={handleMainchainServerStart}
        handleServerRestart={handleMainchainRestart}
        handleServerStop={handleMainchainStop}
        handleServerResync={handleMainchainResync}
      /> 
      <StatusDisplay
        server={rewardsServer}
        label="Rewards Server"
        handleServerStart={handleRewardsStart}
        handleServerRestart={handleRewardsRestart}
        handleServerStop={handleRewardsServerStop}
      />
    </HStack>
  )
}
