import { VStack } from '@chakra-ui/layout'
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Button,
} from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/hooks'
import DeviceModal from 'components/pages/modals/device'
export default function List({
  heading,
  hasStatusBox = false,
  statusBoxColor = 'white',
  issue = '',
  device,
}) {
  const {
    isOpen: isOpenDeviceModal,
    onOpen: onOpenDeviceModal,
    onClose: OnCloseDeviceModal,
  } = useDisclosure()
  const hasIssue = issue.length > 0
  return (
    <AccordionItem>
      <h2>
        <AccordionButton _focus={{ borderColor: 'none' }}>
          <Box flex="1" textAlign="left">
            {heading}
          </Box>
          {hasStatusBox && <Box bg={statusBoxColor} w={5} h={5}></Box>}
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <VStack p={5} alignItems="flex-start">
          <VStack w="full" h="full" alignItems="flex-start">
            {hasIssue && (
              <Text
                mb="0"
                ml="0"
                w="full"
                textAlign="center"
                p={5}
                color="red"
                borderRadius="10"
              >
                {issue}
              </Text>
            )}
            <Text mb="0" ml="0">
              License Type: {device?.Type}
            </Text>
            <Text mb="0" ml="0">
              Wallet Address: {device?.WalletAddress}
            </Text>
            <Text mb="0" ml="0">
              License: {device?.License}
            </Text>
            <Text mb="0" ml="0">
              Serial #: {device?.Serial}
            </Text>
            <VStack w="full" alignItems="flex-end">
              <Button onClick={onOpenDeviceModal}>View Details</Button>
              <DeviceModal
                hasStatusBox={hasStatusBox}
                heading={heading}
                device={device}
                isOpen={isOpenDeviceModal}
                onClose={OnCloseDeviceModal}
              />
            </VStack>
          </VStack>
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  )
}
