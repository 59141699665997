import { VStack } from '@chakra-ui/layout'
import { Heading, Spinner, useDisclosure } from '@chakra-ui/react'
import LicenseHeader from './header'
import LicenseList from './List'
import LicenseSearch from './search'
import ConfirmationDialog from 'components/alertdialogs/confirmation'
import useLicenses from 'hooks/useLicenses'
import { setStorageItem, getStorageItem, removeStorageItem } from 'utils'
export default function License() {
  const {
    licenses,
    generatedLicense,
    generateLicense,
    deleteLicense,
    searchLicenses,
    updateLicense,
    firstLoading,
    secondLoading,
  } = useLicenses()
  const {
    isOpen: isLicenseConfirmDialogOpen,
    onOpen: onOpenLicenseConfirmDialog,
    onClose: onCloseLicenseConfirmDialog,
  } = useDisclosure()
  const handleDeleteLicense = licenseId => {
    setStorageItem('delete_id', licenseId)
    onOpenLicenseConfirmDialog()
  }
  const onConfirmDelete = () => {
    const licenseId = getStorageItem('delete_id')
    deleteLicense(licenseId)
    onCloseLicenseConfirmDialog()
    removeStorageItem('delete_id')
  }
  const onModified = (license) => {
    updateLicense(license.Id, license.Owner, license.Serial)
  }
  if (firstLoading) {
    return (
      <VStack w="full" h="100vh" alignItems="center" justify="center">
        <Spinner size="xl" />
      </VStack>
    )
  }
  return (
    <VStack w="full" h="full" spacing={5} justify="center">
      <ConfirmationDialog
        header="Delete License"
        body="Are you sure? You can't undo this action afterwards."
        isOpen={isLicenseConfirmDialogOpen}
        onClose={onCloseLicenseConfirmDialog}
        onConfirm={onConfirmDelete}
      />
      <Heading>License</Heading>
      <LicenseHeader
        generatedLicense={generatedLicense}
        generateLicense={generateLicense}
      />
      <VStack w="80%">
        <Heading size="md">Licenses</Heading>
        <LicenseSearch query={licenses.query} searchLicenses={searchLicenses} />
        <LicenseList
          licenses={licenses}
          handleDeleteLicense={handleDeleteLicense}
          handleUpdateLicense={onModified}
        />
        {secondLoading && <Spinner size="lg" />}
      </VStack>
    </VStack>
  )
}
