import copy from 'copy-to-clipboard'

export const copyToClipBoard = toCopy => {
  copy(toCopy)
}
export const getScrollPercent = () => {
  const logsElem = document.getElementById('body')
  const h = logsElem.clientHeight
  const scrollH = logsElem.scrollHeight - h
  const percent = Math.abs(logsElem.scrollTop / scrollH)
  return percent
}
export const removeDuplicateArr = (arr, filterFunc) => {
  return arr.filter((v, i, a) => a.findIndex(t => filterFunc(t, v)) === i)
}

export const setStorageItem = (id, value) => {
  window.localStorage.setItem(id, value)
}
export const getStorageItem = id => {
  return window.localStorage.getItem(id)
}
export const removeStorageItem = id => {
  window.localStorage.removeItem(id)
}

export const reloadPage = () => {
  setTimeout(() => {
    window.location.reload()
  }, 1000)
}

export const intervalCodeToString = interval => {
  let convertedInterval = ''
  switch (interval) {
    case '@every 15m':
      convertedInterval = '15 mins'
      break
    case '@every 1h':
      convertedInterval = '1 hour'
      break
    case '@every 2h':
      convertedInterval = '2 hours'
      break
    case '@every 4h':
      convertedInterval = '4 hours'
      break
    case '@every 24h':
      convertedInterval = '24 hours'
      break
    case '@daily':
      convertedInterval = 'Daily'
      break
    case '@every 1w':
      convertedInterval = 'Weekly'
      break
      case '@weekly':
        convertedInterval = 'Weekly'
        break      
    case '@monthly':
      convertedInterval = 'Monthly'
      break
    default:
      break
  }
  return convertedInterval
}
export const toLowerKeys = (obj) => {
  return Object.keys(obj).reduce((accumulator, key) => {
    accumulator[key.toLowerCase()] = obj[key];
    return accumulator;
  }, {});
}