import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

//environment used state (DEBUG/STAGING/RELEASE)
export const environmentState = atom({
  key: 'environmentState',
  default: 'debug',
  effects_UNSTABLE: [persistAtom],
})

export const sessionState = atom({
  key: 'sessionState',
  default: {
    onGoing: [],
    inProgress: null,
    ended: [],
    loading: true,
    sendingRewards: false
  },
})
export const authenticatedState = atom({
  key: 'authenticatedState',
  default: {
    isAuthenticated: false,
  },
})

export const usersState = atom({
  key: 'usersState',
  default: {
    list: [],
    licenses:[],
    loading: true,
    userLoading: true,
    licensesLoading: true,
  },
})

export const devicesState = atom({
  key: 'devicesState',
  default: {
    list: [],
    loading: true,
    devicesLoading: true,
  },
})

export const sessionStatusState = atom({
  key: 'statusState',
  default: {
    0: { message: 'Not yet started' },
    1: { message: 'Payment processing not finished' },
    2: { message: 'Payment Success' },
    3: { message: 'Payment pending - finish now' },
    4: {
      message: 'Payment failed, not enough balance',
    },
    5: {
      message: 'Payment failed, network issue',
    },
    6: {
      message: 'Payment failed, network/database issue found',
    },
    7: {
      message: 'Payment failed, found issue on mainchain',
    },
    8: {
      message: 'Payment success',
    },
    9: {
      message: 'Payment failed, no wallet address provided.',
    },
    10: {
      message: 'Session failed, not finished properly.',
    },
  },
})

export const settingsState = atom({
  key: 'settingsState',
  default: {
    interval: '@every 1m',
    'default-reward': 0.01,
    'transfer-fee': 0.001,
    enabled: false,
    loading: true,
  },
})
export const walletState = atom({
  key: 'walletState',
  default: {
    Address: '',
    Balance: 0,
    Transactions: [],
  },
})

export const licensesState = atom({
  key: 'licensesState',
  default: {
    list: [],
    loading: true,
    query: '',
  },
})

export const generatedLicenseState = atom({
  key: 'generatedLicenseState',
  default: '',
})

export const mainchainServerState = atom({
  key: 'mainchainServerState',
  default: {
    status: 'STOPPED',
  },
})

export const rewardsServerState = atom({
  key: 'rewardsServerState',
  default: {
    status: 'STOPPED',
  },
})
