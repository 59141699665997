import { VStack } from '@chakra-ui/layout'
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Button,
} from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/hooks'
import UserModal from 'components/pages/modals/users/user'
export default function List({
  heading,
  hasStatusBox = false,
  statusBoxColor = 'white',
  user,
}) {
  const {
    isOpen: isOpenUserModal,
    onOpen: onOpenUserModal,
    onClose: OnCloseUserModal,
  } = useDisclosure()
  return (
    <AccordionItem>
    {({ isExpanded }) => (<>
      <h2>
        <AccordionButton _focus={{ borderColor: 'none' }}>
          <Box flex="1" textAlign="left">
            {heading}
          </Box>
          {hasStatusBox && <Box bg={statusBoxColor} w={5} h={5}></Box>}
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <VStack p={5} alignItems="flex-start">
          <VStack w="full" h="full" alignItems="flex-start">
            <Text mb="0" ml="0">
              Id: {user?.Id}
            </Text>
            <Text mb="0" ml="0">
              Username: {user?.Username}
            </Text>
            <Text mb="0" ml="0">
              Name: {user?.Name}
            </Text>            
            <VStack w="full" alignItems="flex-end">
              <Button onClick={onOpenUserModal}>View Details</Button>
              {isExpanded && <>
                  <UserModal
                  heading={heading}
                  user={user}
                  isOpen={isOpenUserModal}
                  onClose={OnCloseUserModal}
                />              
              </>}
              </VStack>
          </VStack>
        </VStack>
      </AccordionPanel>
    </>)}
    </AccordionItem>
  )
}
