import { useEffect } from 'react'
import { createPortal } from 'react-dom'
import { FormControl, FormLabel } from '@chakra-ui/form-control'
import { GridItem, SimpleGrid } from '@chakra-ui/layout'
import { VStack } from '@chakra-ui/layout'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Input,
  Button,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { requestSendEla } from 'state/actions/wallet'
export default function Transfer({ heading, isOpen, onClose, address = '' }) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm()
  const toast = useToast()
  useEffect(() => {
    const setAddress = () => {
      if (address?.length > 0) {
        setValue('recipient-address', address)
      }
    }
    setAddress()
  }, [address, setValue])
  const disableRecipientAddressInput = address?.length > 0
  const handleOnSubmit = async addressInfo => {
    const updatedAddressInfo = {
      ...addressInfo,
      amount: parseFloat(addressInfo.amount),
    }
    const { code, data } = await requestSendEla(updatedAddressInfo)
    if (code === 200) {
      setValue('recipient-address', '')
      setValue('amount', '0.01')
      toast({
        title: 'Ela sent',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })
      return
    }
    toast({
      title: data,
      status: 'warning',
      duration: 2000,
      isClosable: true,
      position: 'top',
    })
  }
  return createPortal(
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Transfer</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack w="full" h="full" alignItems="flex-start">
            <Text>{heading}</Text>
            <form
              onSubmit={handleSubmit(handleOnSubmit)}
              style={{ width: '100%' }}
            >
              <SimpleGrid w="full" h="full" rowGap="3">
                <GridItem>
                  <FormControl isInvalid={errors['recipeint-address']}>
                    <FormLabel>Address</FormLabel>
                    <Input
                      id="recipient-address"
                      placeholder="Wallet Address"
                      {...register('recipient-address', {
                        required: 'This is required',
                      })}
                      isDisabled={disableRecipientAddressInput}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isInvalid={errors['amount']}>
                    <FormLabel>Amount</FormLabel>
                    <NumberInput
                      id="amount"
                      defaultValue={0.01}
                      min={0}
                      step={0.01}
                    >
                      <NumberInputField
                        id="amount"
                        {...register('amount', {
                          required: 'This is required',
                        })}
                      />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <Button type="submit" isFullWidth isLoading={isSubmitting}>
                    Submit
                  </Button>
                </GridItem>
              </SimpleGrid>
            </form>
          </VStack>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>,
    document.getElementById('modal')
  )
}
