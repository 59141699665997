import { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useToast } from '@chakra-ui/react'
import { sessionState, sessionStatusState } from 'state/atom'
import useScroll from 'hooks/useScroll'
import {
  requestInprogressSession,
  requestUpcomingSessions,
  requestEndedSessions,
  requestChangeAwardAmount,
  requestAddUpcomingSession,
  requestResendSessionRewards,
} from 'state/actions/session'
export default function useSession(loadHistory = false) {
  const [sessions, setSessions] = useRecoilState(sessionState)
  const { offset } = useScroll(loadHistory)
  const sessionStatus = useRecoilValue(sessionStatusState)
  const toast = useToast()
  const firstHistoryLoading = sessions?.loading && sessions?.ended?.length === 0
  const secondHistoryLoading = sessions?.loading && sessions.ended.length > 0
  const isSendingRewards = sessions?.sendingRewards
  const handleRequestSessions = async () => {
    try {
      setSessions({ ...sessions, loading: true })
      if (!loadHistory) {
        const onGoing = await requestUpcomingSessions()
        const inProgress = await requestInprogressSession()
        setSessions({ ...sessions, onGoing: onGoing, inProgress: inProgress, loading: false })
      }
    } catch (error) {
      toast({
        title: 'Cannot get sessions',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })
    }
  }
  const addSession = async awardAmount => {
    try {
      await requestAddUpcomingSession(awardAmount)
      toast({
        title: 'Session Added.',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })
    } catch (error) {
      toast({
        title: 'Session not Added.',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })
    } finally {
      await handleRequestSessions()
    }
  }
  const updateSession = async session => {
    try {
      await requestChangeAwardAmount(session.Id, session.AwardAmount)
    } catch (error) {
      toast({
        title: 'Cannot update amount',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })
    }
  }
  // update session status id
  const updateSessionStatus = async updatedSession => {
    try {
      const updatedEndedSessions=sessions.ended.map(session=>{
        if(session.Id===updatedSession.Id){
          return updatedSession
        }
        return session
      })
      setSessions({...sessions,ended:updatedEndedSessions})      
    } catch (error) {
      toast({
        title: 'Cannot update status',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })
    }
  }
  const resendRewards= async session => {
    try{
     setSessions({...sessions,sendingRewards:true})
     const response = await requestResendSessionRewards(session.Id)
     setSessions({...sessions,sendingRewards:false})     
     return response
    }
    catch(error){
      toast({
        title: 'Cannot resend session rewards',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })      
    }
  }
  useEffect(() => {
    try {
      const handleRequestSessionHistory = async () => {
        if (loadHistory) {
          setSessions({ ...sessions, loading: true })
          await requestEndedSessions(offset)
        }
      }
      handleRequestSessionHistory()
    } catch (error) {
      toast({
        title: 'Cannot get sessions',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })
    }
    //eslint-disable-next-line
  }, [setSessions, offset])
  useEffect(() => {
    handleRequestSessions()
    //eslint-disable-next-line
  }, [setSessions])
  return {
    sessions,
    sessionStatus,
    firstHistoryLoading,
    secondHistoryLoading,
    isSendingRewards,
    addSession,
    updateSession,
    updateSessionStatus,
    resendRewards
  }
}
