import { useState } from 'react'
import { SearchIcon, CloseIcon } from '@chakra-ui/icons'
import { HStack } from '@chakra-ui/layout'
import { IconButton, Input } from '@chakra-ui/react'
export default function LicenseSearch({ query, searchLicenses }) {
  const [textInputValue, setTextInputValue] = useState('')
  const hasQuery = query?.length > 0
  const handleQueryChange = event => {
    setTextInputValue(event.target.value?.trim())
  }
  const handleSearchLicense = () => {
    searchLicenses(textInputValue)
  }
  const handleKeyPressed = e => {
    if (e.key === 'Enter') {
      handleSearchLicense()
    }
  }
  const handleClear = () => {
    searchLicenses('')
    setTextInputValue('')
  }
  return (
    <HStack w="40%" mb={3} justifyContent="center">
      <Input
        placeholder="Search"
        value={hasQuery ? query : textInputValue}
        disabled={hasQuery}
        onChange={handleQueryChange}
        onKeyPress={handleKeyPressed}
      />
      {hasQuery > 0 ? (
        <>
          <IconButton
            aria-label="Clear"
            icon={<CloseIcon />}
            onClick={handleClear}
          />
        </>
      ) : (
        <>
          <IconButton
            aria-label="Search"
            icon={<SearchIcon />}
            onClick={handleSearchLicense}
          />
        </>
      )}
    </HStack>
  )
}
