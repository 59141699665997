import { VStack } from '@chakra-ui/layout'
import { Heading } from '@chakra-ui/react'
import React from 'react'

export default function Controller(props) {
  const { alignItems = 'center', heading = '', children } = props
  return (
    <VStack w="90%" h="full" ml="9.5vw" px={5} alignItems={alignItems}>
      <Heading size="lg" pb={10}>
        {heading}
      </Heading>
      {children}
    </VStack>
  )
}
