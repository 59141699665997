import { useState, useEffect } from 'react'
let lastScrollTop = 0
const getDocHeight = () => {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  )
}
const getScrollPercent = () => {
  var winheight =
    window.innerHeight ||
    (document.documentElement || document.body).clientHeight
  var docheight = getDocHeight()
  var scrollTop =
    window.pageYOffset ||
    (document.documentElement || document.body.parentNode || document.body)
      .scrollTop
  var trackLength = docheight - winheight
  var pctScrolled = Math.floor((scrollTop / trackLength) * 100) // gets percentage scrolled (ie: 80 or NaN if tracklength == 0)
  return pctScrolled
}
export default function useScroll(enableScroll=true) {
  const [offset, setOffset] = useState(0)
  useEffect(() => {
    const handleScroll = () => {
      var st = window.pageYOffset || document.documentElement.scrollTop
      if (st > lastScrollTop) {
        const percent = getScrollPercent()
        if (percent >= 99 && enableScroll) {
          setOffset(offset + 20)
        }
      }
      lastScrollTop = st <= 0 ? 0 : st
    }
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [offset,enableScroll])
  return {offset,setOffset}
}
