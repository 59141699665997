import { HStack, Input } from "@chakra-ui/react";

export const InputX = ({
    edit = false, 
    value, 
    placeholder="", 
    width='auto',
    onChange=null,
    caption=''}) => {
    return (<>
        { !edit && <p>{caption} {value}</p>}
        { edit && <HStack>
            <p>{caption}</p>
            <Input 
                placeholder={placeholder} 
                width={width} 
                value={value}
                onChange={onChange}/>
            </HStack>
        }
    </>)
};