import { promiseGetRecoil } from 'recoil-outside'
import { event_server } from 'Socket'
import { environmentState } from 'state/atom'
export const requestServerStatus = async () => {
    const mode = await promiseGetRecoil(environmentState)
    const token = localStorage.getItem('auth_token')
    event_server.emit(
        "mainchain-server",
        { status: 'status', mode, token }
    )
}
export const requestServerStart = async () => {
    const mode = await promiseGetRecoil(environmentState)
    const token = localStorage.getItem('auth_token')
    event_server.emit(
        "mainchain-server",
        { status: 'start', mode, token }
    )
}
export const requestServerRestart = async () => {
    const mode = await promiseGetRecoil(environmentState)
    const token = localStorage.getItem('auth_token')
    event_server.emit(
        "mainchain-server",
        { status: 'restart', mode, token }
    )
}
export const requestServerStop = async () => {
    const mode = await promiseGetRecoil(environmentState)
    const token = localStorage.getItem('auth_token')
    event_server.emit(
        "mainchain-server",
        { status: 'stop', mode, token })
}

export const requestResyncServer = async () => {
    const mode = await promiseGetRecoil(environmentState)
    const token = localStorage.getItem('auth_token')
    event_server.emit(
        "mainchain-server",
        { status: 'resync', mode, token },
    )
}