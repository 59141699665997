import {
  Accordion,
  Spinner,
  VStack,
  Button,
  Box,
  Divider,
  useDisclosure,
} from '@chakra-ui/react'
import Controller from 'components/pages/rewards/controller'
import useSession from 'hooks/useSession'
import useMainchainServerStatus from 'hooks/useMainchain'
import useRewardServerStatus from "hooks/useRewardsServer"
import { AddIcon } from '@chakra-ui/icons'
import SessionDialog from './createSessionDialog'
import ServerStatus from 'components/pages/rewards/dashboard/serverstatus'
import InProgress from "components/pages/rewards/dashboard/sessions/inprogress"
import UpComing from "components/pages/rewards/dashboard/sessions/upcoming"
const Toolbar = ({ addSession }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Box>
        <Button variant="ghost" rightIcon={<AddIcon />} onClick={onOpen}>
          Upcoming
        </Button>
      </Box>
      <SessionDialog isOpen={isOpen} onClose={onClose} onAdded={addSession} />
    </>
  )
}

export default function Dashboard() {
  const { sessions, addSession, sessionStatus, updateSession } = useSession()
  const {
    server: mainchainServer,
    handleServerRestart: handleMainchainRestart,
    handleServerStart: handleMainchainServerStart,
    handleServerStop: handleMainchainStop,
    handleServerResync: handleMainchainResync }
    = useMainchainServerStatus()
  const {
    server: rewardsServer,
    handleServerStart: handleRewardsStart,
    handleServerRestart: handleRewardsRestart,
    handleServerStop: handleRewardsServerStop }
    = useRewardServerStatus()
  if (sessions.loading) {
    return (
      <Controller heading="Dashboard">
        <VStack w="full" h="55vh" alignItems="center" justify="center">
          <Spinner size="xl" />
        </VStack>
      </Controller>
    )
  }
  return (
    <Controller heading="Dashboard">
      <ServerStatus
        mainchainServer={mainchainServer}
        rewardsServer={rewardsServer}
        handleMainchainServerStart={handleMainchainServerStart}
        handleMainchainRestart={handleMainchainRestart}
        handleMainchainStop={handleMainchainStop}
        handleMainchainResync={handleMainchainResync}
        handleRewardsStart={handleRewardsStart}
        handleRewardsRestart={handleRewardsRestart}
        handleRewardsServerStop={handleRewardsServerStop}
      />
      <Divider />
      <Toolbar addSession={addSession} />
      <Accordion w="full">
        <UpComing sessions={sessions} sessionStatus={sessionStatus} updateSession={updateSession} />
        {sessions.inProgress && <InProgress sessions={sessions} sessionStatus={sessionStatus} updateSession={updateSession} />}
      </Accordion>
    </Controller>
  )
}