import { extendTheme, theme as base } from '@chakra-ui/react'

export default extendTheme({
  colors: {
    brand: {
      50: '#c9e4ff',
      100: '#aed7ff',
      200: ' #93caff',
      300: '#78bdff',
      400: '#5db0ff',
      500: ' #43a3ff',
      600: '#2896ff',
      700: '#0d89fe',
      800: '#004486',
      900: ' #004e98',
    },
  },
  fonts: {
    heading: `Monserrat,${base.fonts?.heading}`,
    body: `Inter,${base.fonts?.body}`,
  },
})
