import { promiseSetRecoil, promiseGetRecoil } from 'recoil-outside'
import { event_server } from 'Socket'
import {
    licensesState,
    environmentState,
} from 'state/atom'
import { removeDuplicateArr } from 'utils'

export const requestLicenses = async (offset, query = '') => {
    const mode = await promiseGetRecoil(environmentState)
    const token = localStorage.getItem('auth_token')
    event_server.emit(
        'licenses-send',
        { status: 'get-license', mode, token, offset, query },
        async result => {
            console.log('sended successfully')
            console.log(result)
            result = JSON.parse(result)
            const { data } = result
            const licenses = await promiseGetRecoil(licensesState)
            if (data?.length > 0) {
                const updatedList = removeDuplicateArr(
                    [...licenses.list, ...data],
                    (t, v) => t.Id === v.Id
                )
                await promiseSetRecoil(licensesState, {
                    ...licenses,
                    list: updatedList,
                    loading: false,
                })
            } else {
                await promiseSetRecoil(licensesState, {
                    ...licenses,
                    loading: false,
                })
            }
        }
    )
}
export const requestDeleteLicense = id => {
    return new Promise(async (resolve, reject) => {
        try {
            const mode = await promiseGetRecoil(environmentState)
            const token = localStorage.getItem('auth_token')
            event_server.emit(
                'licenses-send',
                { status: 'delete-license', mode, token, id },
                result => {
                    resolve(result.data)
                }
            )
        } catch (error) {
            reject(error)
        }
    })
}
export const requestAddLicense = (lctype, username) => {
    return new Promise(async (resolve, reject) => {
        try {
            const mode = await promiseGetRecoil(environmentState)
            const token = localStorage.getItem('auth_token')
            event_server.emit(
                'licenses-send',
                {
                    status: 'create',
                    mode,
                    token,
                    data: {
                        lctype,
                        owner: username,
                        mode,
                    },
                },
                result => {
                    try {
                        result = JSON.parse(result)
                        resolve(result.data)
                            
                    } catch (error) {
                        reject(result)                        
                    }
                }
            )
        } catch (error) {
            reject(error)
        }
    })
}

export const requestUpdateLicense = (licenseId, owner, deviceSerial) => {
    return new Promise(async (resolve, reject) => {
        try {
            const mode = await promiseGetRecoil(environmentState)
            const token = localStorage.getItem('auth_token')
            event_server.emit(
                'licenses-send',
                {
                    status: 'update-license',
                    mode,
                    token,
                    id: licenseId,
                    data: {
                        owner,
                        serial: deviceSerial,
                    },
                },
                result => {
                    try {
                        result = JSON.parse(result)
                        resolve(result.data)
                            
                    } catch (error) {
                        reject(result)                        
                    }
                }
            )
        } catch (error) {
            reject(error)
        }
    })
}