import {useEffect, useState} from "react"
import { createPortal } from 'react-dom'
import { FormControl, FormLabel,FormErrorMessage } from '@chakra-ui/form-control'
import { VStack,HStack } from '@chakra-ui/layout'
import { CloseIcon } from '@chakra-ui/icons'
import { useForm } from 'react-hook-form'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  SimpleGrid,
  GridItem,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  Input,
  TabPanel,
  IconButton,
  Spinner
} from '@chakra-ui/react'
import { format,parseISO } from 'date-fns'
import useUsers from 'hooks/useUser'
import License from 'components/pages/users/license'
// import TransferModal from 'components/pages/modals/transfer'
export default function User({heading, user, isOpen, onClose, children }) {
  const [isEditting,setisEditting] = useState(false)
  const {users, searchLicensesByOwner, updateUser , assignLicense,clearLicenseOwner} = useUsers()
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm()  
  const { licenses,licensesLoading } = users
  const handleEdit= e =>{
    e.preventDefault()
    setisEditting(!isEditting)
  }
  const handleOnSubmit = async data =>{
    const updatedUser = {
      ...user,
      ...data
    }
    await updateUser(updatedUser)
    setisEditting(false)
  }
  const handleOnCloseModal = () =>{
    setisEditting(false)
    onClose()
  }
  const handleAssignLicense = (licenseType , owner) =>{
    assignLicense(licenseType,owner)
  }
  const handleClearLicenseOwner = async (license,owner) =>{
    clearLicenseOwner(license,owner)
  }
  useEffect(()=>{
    searchLicensesByOwner(user.Did)
  // eslint-disable-next-line
  },[user.Id])
  useEffect(()=>{
    const updateFormValues = () => {
      setValue("Name",user.Name)
      setValue("Email",user.Email)
      setValue("Did",user.Did)    
    }    
    updateFormValues()
    // eslint-disable-next-line    
  },[users, setValue, isEditting])
  return createPortal(
    <Modal isOpen={isOpen} onClose={handleOnCloseModal} isCentered size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>User Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack w="full" h="full" alignItems="flex-start">
            <Text>{heading}</Text>
            <form onSubmit={handleSubmit(handleOnSubmit)} style={{ width: '100%' }}>
            <SimpleGrid w="full" h="full" rowGap="3">
                <GridItem>
                  <FormControl isInvalid={errors.Name}>
                    {isEditting ? (
                      <>
                        <FormLabel>Name</FormLabel>
                        <Input
                          id="Name"
                          placeholder="Name"
                          {...register('Name')}
                        />
                      </>
                    ) : (
                      <Text>
                        Name: {user.Name}
                      </Text>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isInvalid={errors.Email}>
                    {isEditting ? (
                      <>
                        <FormLabel>Email</FormLabel>
                        <Input
                          id="Email"
                          placeholder="Email"
                          {...register(
                            'Email',
                            {
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              }                              
                            }
                          )}
                        />
                        <FormErrorMessage>Invalid email address</FormErrorMessage>
                      </>
                    ) : (
                      <Text>
                        Email: {user.Email}
                      </Text>
                    )}
                  </FormControl>
                </GridItem>  
                <GridItem>
                  <FormControl isInvalid={errors.Did}>
                    {isEditting ? (
                      <>
                        <FormLabel>Did</FormLabel>
                        <Input
                          id="Did"
                          placeholder="Did"
                          {...register('Did')}
                        />
                      </>
                    ) : (
                      <Text>
                        Did: {user.Did}
                      </Text>
                    )}
                  </FormControl>
                </GridItem>   
              </SimpleGrid>            
              <Text mt={5}>
                Created Date:{' '}
                {format(parseISO(user?.CreatedAt),'MM-dd-yyyy hh:mm:ss a')}
              </Text>            
              <HStack w="full" h="full" justify="flex-end">
                {isEditting ? <>
                  <Button 
                  type="submit" 
                  colorScheme="brand" 
                  isLoading={isSubmitting}> 
                  Update </Button>
                  <Button onClick={handleEdit}>Cancel</Button>
                </>: 
                <Button type="button" onClick={handleEdit}> Edit </Button>}
              </HStack>                  
            </form>                                                      
            <Tabs w="full" variant="enclosed" align="start" size="sm" isLazy>
              <TabList>
                <Tab>
                  <HStack w="full" h="full">
                    <Text>Licenses</Text>
                  </HStack>                                    
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                <License 
                user={user}
                assignLicense = {handleAssignLicense}
                />                       
                <VStack w="full" h="full" maxHeight={100} overflowY="auto" alignItems={licensesLoading ? "center":"flex-start"} p={5}>
                    {licensesLoading && <Spinner size="md"/>}
                    {!licensesLoading && licenses.map(license => (<HStack key={license.Id} w="full" h="full" alignItems="center">
                        <Text>{license.Id}</Text>
                        <IconButton
                          aria-label="Delete License"
                          size="sm"
                          variant="outline"
                          colorScheme="red"
                          icon={<CloseIcon />}
                          onClick={() => {
                            handleClearLicenseOwner(license.Id,user.Did)
                          }}
                          />                        
                      </HStack>))}
                  </VStack>                  
                </TabPanel>
                <TabPanel>
                  <p>No mockups yet</p>
                </TabPanel>
                <TabPanel>
                  <p>No mockups yet</p>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        </ModalBody>
        <ModalFooter>
          {/* <Button colorScheme="blue" mr={3} onClick={onClose}>

          </Button>
          <Button variant="ghost">Secondary Action</Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>,
    document.getElementById('modal')
  )
}
