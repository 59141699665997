import React from 'react'
import { formatDistance } from 'date-fns'
import RewardsList from 'components/pages/rewards/list'

export default function Upcoming({ sessions, sessionStatus, updateSession }) {
    return <>
        {sessions.onGoing?.map((Session, index) => {
            const startAt = new Date(Session.StartAt * 1000)
            let heading = `${formatDistance(startAt, Date.now(), {
                addSuffix: true,
            })}`
            if (heading.includes('ago')) {
                heading = 'Already Started'
            } else {
                heading = `${heading.replace('in about', '')} left before starts`
            }
            const status = `UPCOMING`
            return (
                <RewardsList
                    key={index}
                    status={status}
                    heading={heading.toUpperCase()}
                    displayUsers={false}
                    hasStatusBox
                    statusBoxColor="grey"
                    session={Session}
                    sessionStatus={sessionStatus}
                    updateSession={updateSession}
                />
            )
        })}
    </>
}
