import { useEditableControls, ButtonGroup, IconButton } from '@chakra-ui/react'
import { CloseIcon, EditIcon, CheckIcon } from '@chakra-ui/icons'
//component for edit controls
export default function EditableControls() {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls()
  return (
    <span style={{ marginLeft: 5 }}>
      {isEditing ? (
        <ButtonGroup justifyContent="center" size="sm">
          <IconButton icon={<CheckIcon />} {...getSubmitButtonProps()} />
          <IconButton icon={<CloseIcon />} {...getCancelButtonProps()} />
        </ButtonGroup>
      ) : (
        <IconButton size="sm" icon={<EditIcon />} {...getEditButtonProps()} />
      )}
    </span>
  )
}
