import io from 'socket.io-client'
import { SERVER_URL } from './config'
import { consolewrite } from 'log'

export const event_server = io(SERVER_URL.split('://')[1].trim(), {
  transports: ['websocket'],
})
event_server.on('connect', () => {
  consolewrite('socket connected')
})
event_server.on('connect_error', () => {
  consolewrite('Failed connecting to socket')
})
event_server.on('disconnect', reason => {
  consolewrite('Disconnected socket.', reason)
})
function Socket({ children }) {
  return children
}
export default Socket
