import { VStack, Heading } from '@chakra-ui/react'
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useSetRecoilState } from 'recoil'
import { requestAuth } from 'state/actions/user'
import { authenticatedState } from 'state/atom'
export default function Login() {
  const setAuthState = useSetRecoilState(authenticatedState)
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm()
  const toast = useToast()
  const handleOnSubmit = async creds => {
    const token = await requestAuth(creds)
    if (token?.length > 0) {
      localStorage.setItem('auth_token', token)
      setAuthState({ isAuthenticated: true })
      return
    }
    toast({
      title: 'Invalid credentials',
      status: 'warning',
      duration: 2000,
      isClosable: true,
      position: 'bottom',
    })
  }
  return (
    <VStack
      w="full"
      height="full"
      p={3}
      mt="24vh"
      spacing={4}
      alignItems="center"
    >
      <Heading size="lg">Elabox Rewards Admin</Heading>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <FormControl isInvalid={errors['username']}>
          <FormLabel>Username</FormLabel>
          <Input
            id="username"
            placeholder="Username"
            {...register('username', {
              required: 'This is required',
            })}
          />
        </FormControl>
        <FormControl isInvalid={errors['password']}>
          <FormLabel>Password</FormLabel>
          <Input
            id="password"
            type="password"
            placeholder="Password"
            {...register('password', {
              required: 'This is required',
            })}
          />
        </FormControl>
        <Button type="submit" mt={5} isFullWidth isLoading={isSubmitting}>
          Login
        </Button>
      </form>
    </VStack>
  )
}
