import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { useToast } from '@chakra-ui/react'
import { usersState } from 'state/atom'
import { toLowerKeys } from "utils"
import useScroll from 'hooks/useScroll'
import { requestUsers,requestLicensesByOwner,
  requestUpdateUser, requestClearLicenseOwner,
  requestAssignLicense,
 } from 'state/actions/user'
export default function useUser() {
  const [query, setQuery] = useState('')
  const [users, setUsers] = useRecoilState(usersState)
  const { offset, setOffset } = useScroll()
  const toast = useToast()
  const pageLoading = users?.loading
  const userLoading = users?.userLoading
  const fetchUsers = async (clear = false) => {
    setOffset(0)
    setUsers({
      ...users,
      list: clear ? [] : users.list,
      userLoading: true,
    })
    await requestUsers(offset, query)
  }
  const searchLicensesByOwner = async owner =>{
    setUsers({
      ...users,
      licenses:[],
      licensesLoading:true
    })
    await requestLicensesByOwner(owner)
    setUsers({
      ...users,
      licenses: users.licenses,
      licensesLoading:false,
    })    
  }
  const updateUser = async updatedUser =>{
    try {
      await requestUpdateUser(toLowerKeys(updatedUser))
      const updatedUserList = users.list.map(user => {
        if(user.Id === updatedUser.Id){
          return updatedUser
        }
        return user
      })
      setUsers({...users,list: updatedUserList})
      toast({
        title: 'User updated',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })
    } catch (error) {
      toast({
        title: error,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })
    }    
  }
  const clearLicenseOwner = async (license,owner) => {
    try{
      setUsers({
        ...users,
        licensesLoading: true})
      await requestClearLicenseOwner(license,owner)
      setUsers({
        ...users,
        licenses: users.licenses.filter(l => l.Id !== license),
        licensesLoading: false
      })
      toast({
        title: 'License removed',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })      
    }
    catch(error){
      toast({
        title: error,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })      
    }
  }
  const assignLicense = async (licenseType,owner) => {
    try{
      setUsers({
        ...users,
      licensesLoading: true})
      const assignedLicense = await requestAssignLicense(licenseType,owner)
      
      setUsers({
        ...users,
        licenses: [assignedLicense,...users.licenses],
      })
      toast({
        title: 'License Assigned',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })      
    }
    catch(error){
      console.log(error)
      toast({
        title: error,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })      
    } finally {
      setUsers(prevState=>({
        ...prevState,
        licensesLoading: false
      }))
    }
  }
  useEffect(() => {
    fetchUsers(false, query)
    // eslint-disable-next-line
  }, [offset])
  return {
    users,
    pageLoading,
    userLoading,
    fetchUsers,
    updateUser,
    searchLicensesByOwner,
    clearLicenseOwner,
    assignLicense,
    query,
    setQuery,
  }
}
