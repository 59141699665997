import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { rewardsServerState } from 'state/atom'
import { event_server } from "Socket"
import { requestServerStatus, requestServerRestart, requestServerStop, requestServerStart } from 'state/actions/rewards'
export default function useServerStatus() {
  const PROCESS = "rewards"
  const [server, setServer] = useRecoilState(rewardsServerState)
  const handleServerStart = () => {
    requestServerStart(PROCESS)
  }
  const handleServerRestart = () => {
    requestServerRestart(PROCESS)
  }
  const handleServerStop = () => {
    requestServerStop(PROCESS)
  }
  const fetchServerStatus = async () => {
    requestServerStatus(PROCESS)
  }
  useEffect(() => {
    event_server.on("reward-server", status => {
      setServer({
        ...status,
      })
    })
    fetchServerStatus()
    const intervalId = setInterval(fetchServerStatus, 10000)
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [])
  return { server, handleServerRestart, handleServerStart, handleServerStop }
}
