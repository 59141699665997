import { useState } from 'react'
import { format } from 'date-fns'
import {
  VStack,
  Heading,
  Text,
  HStack,
  Button,
  Progress,
  useDisclosure,
} from '@chakra-ui/react'
import ConfirmationDialog from 'components/alertdialogs/confirmation'

export default function StatusDisplay({ server, label, handleServerRestart = () => { }, handleServerStop = () => { }, handleServerStart = () => { }, handleServerResync = () => { } }) {
  const [operation, setOperation] = useState('')
  const {
    isOpen: isConfirmDialogOpen,
    onOpen: onOpenConfirmDialog,
    onClose: onCloseConfirmDialog,
  } = useDisclosure()
  const onCofirm = () => {
    switch (operation) {
      case 'Start':
        handleServerStart()
        break
      case 'Restart':
        handleServerRestart()
        break
      case 'Stop':
        handleServerStop()
        break
      case 'Resync':
        handleServerResync()
        break
      default:
        break
    }
    onCloseConfirmDialog()
  }
  const handleServerFunction = e => {
    const operation = e.target.innerHTML.trim()
    setOperation(operation)
    onOpenConfirmDialog()
  }
  const { status, blockHeight, latestBlock } = server
  const statusColor = status !== 'STOPPED' && status !== "STOPPING" && status !== "FAILED" ? 'green.600' : 'red.600'
  const displayBigStatus =
    label === 'Rewards Server' ||
    status === 'STOPPED' ||
    status === 'STOPPING' ||
    status === 'RUNNING' ||
    status === 'INITIALIZING' ||
    status === 'STARTING' || status === "RESYNCING" || status === "FAILED"
  const hideStopButton = status === 'STOPPED' && displayBigStatus
  const hideAllButtons =
    status === "STOPPING" ||
    status === "RESTARTING" ||
    status === "STARTING" ||
    status === "RESYNCING"
  const stopButtonLabel = label !== 'Rewards Server' ? 'Resync' : 'Stop'
  const startButtonLabel = status === "STOPPED" ? "Start" : "Restart"
  const latestDate = new Date(latestBlock?.blocktime * 1000)
  const displayStats =
    status !== 'STOPPED' &&
    blockHeight &&
    latestBlock?.blocktime &&
    latestBlock?.percentage
  return (
    <VStack
      h="250"
      borderWidth="1px"
      p="5"
      borderRadius="lg"
      alignItems="center"
      justifyContent="space-around"
    >
      <ConfirmationDialog
        header={`${operation} Server`}
        body={`Are you sure you want to ${operation}?`}
        confirmLabel={operation}
        confirmLabelColor={operation === "Start" ? "green" : "red"}
        isOpen={isConfirmDialogOpen}
        onClose={onCloseConfirmDialog}
        onConfirm={onCofirm}
      />
      <Heading size="sm">{label}</Heading>
      {displayBigStatus && (
        <>
          <Heading size="sm" colorScheme="gray">
            Status
          </Heading>
          <Text color={statusColor} fontSize="sm">
            {status}
          </Text>
        </>
      )}
      {displayStats && (
        <>
          <Text color="gray.600" fontSize="sm">
            Block Height: {blockHeight}
          </Text>
          <Text color="gray.600" fontSize="sm">
            Latest {format(latestDate, 'MM/dd/yyyy')}
          </Text>
          {!displayBigStatus && (
            <Text color={statusColor} fontSize="sm">
              Status: {status}
            </Text>
          )}
          <Progress
            w="full"
            colorScheme="green"
            value={latestBlock?.percentage}
          />
        </>
      )}
      <HStack
        w="full"
        justifyContent={`${hideStopButton ? 'center' : 'space-between'}`}
      >
        {!hideAllButtons && (
          <>
            {!hideStopButton && (
              <Button
                variant="ghost"
                colorScheme="red"
                onClick={handleServerFunction}
              >
                {stopButtonLabel}
              </Button>
            )}
            <Button variant="ghost" onClick={handleServerFunction}>
              {startButtonLabel}
            </Button>
          </>
        )}
      </HStack>
    </VStack>
  )
}
