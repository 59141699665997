import {useState} from 'react'
import { HStack } from '@chakra-ui/layout'
import {
  Select,
  IconButton,
} from '@chakra-ui/react'
import {AddIcon} from '@chakra-ui/icons'
export default function License({user,assignLicense }) {
  const [licenseType,setLicenseType] = useState("")
  const disableAssign = licenseType === ""
  const handleLicenseTypeSelectChange = e => {
    const { target } = e
    if (target.value?.length > 0) {
      setLicenseType(target.value)
    } else {
      setLicenseType("")
    }
  } 
  const handleAssignLicense = async () =>{
    await assignLicense(licenseType,user.Did)
    setLicenseType("")
  }
  return <>
        <HStack w="full" h="full" pb="2" justifyContent="flex-start" style={{marginTop:10,marginBotom:10}} >
            <Select
                id="type"
                placeholder="Select License"
                size="sm"
                value={licenseType}
                onChange={handleLicenseTypeSelectChange}
            >
            <option value="premium">Premium</option>
            <option value="regular">Regular</option>
            </Select>       
            <IconButton
                aria-label="Add License"
                size="sm"
                colorScheme="blue"
                disabled={disableAssign}
                icon={<AddIcon />}
                onClick={handleAssignLicense}
            />      
        </HStack>
  </>
}
