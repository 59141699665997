import { useEffect, useState } from 'react'
import { Container, VStack } from '@chakra-ui/react'
import { ColorModeSwitcher } from './ColorModeSwitcher'
import Dashboard from 'components/pages'
import { useRecoilState } from 'recoil'
import { authenticatedState } from 'state/atom'
import Login from 'components/login'
import { requestCheckToken } from 'state/actions/user'
function App() {
  const [isLoading, setIsLoading] = useState(true)
  const [authState, setAuthState] = useRecoilState(authenticatedState)
  useEffect(() => {
    const handleCheckToken = async () => {
      try {
        const result = await requestCheckToken()
        setAuthState({ isAuthenticated: result.authorized })
      } catch (error) {
        localStorage.removeItem('auth_token')
        setAuthState({ isAuthenticated: false })
      } finally {
        setIsLoading(false)
      }
    }
    handleCheckToken()
  }, [setAuthState])
  if (isLoading) {
    return null
  }
  return (
    <Container maxW="container." p={5}>
      <VStack w="full" h="full">
        {authState.isAuthenticated ? <Dashboard /> : <Login />}
        <VStack
          w="full"
          h="10%"
          alignItems="flex-end"
          style={{ position: 'fixed', bottom: -10, right: 10, zIndex: 100 }}
        >
          <ColorModeSwitcher />{' '}
        </VStack>
      </VStack>
    </Container>
  )
}

export default App
