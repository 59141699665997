import { consolewrite } from 'log'
import { promiseSetRecoil, promiseGetRecoil } from 'recoil-outside'
import { event_server } from 'Socket'
import {
    sessionState,
    environmentState,
} from 'state/atom'
import { removeDuplicateArr } from 'utils'



export const requestUpcomingSessions = () => {
    //send inquiry to socket for upcoming sessions
    return new Promise(async (resolve, reject) => {
        try {
            const mode = await promiseGetRecoil(environmentState)
            const token = localStorage.getItem('auth_token')
            event_server.emit(
                'session',
                { status: 'upcoming', mode, token },
                async result => {
                    //console.log('upcoming session result ', result)
                    const { data } = JSON.parse(result)
                    resolve(data)
                }
            )
        } catch (error) {
            reject(false)
        }
    })
}

export const requestInprogressSession = () => {
    return new Promise(async (resolve, reject) => {
        try {
            consolewrite('Load inprogress sessions...')
            const mode = await promiseGetRecoil(environmentState)
            const token = localStorage.getItem('auth_token')
            //send inquiry to socket for inprogress sessions
            event_server.emit(
                'session',
                { status: 'inprogress', mode, token },
                async result => {
                    console.log(result)
                    const { data } = JSON.parse(result)
                    consolewrite('inprogress session result', data)
                    resolve(data)
                }
            )
        } catch (error) {
            reject(false)
        }
    })
}

export const requestEndedSessions = (offset = 0, limit = 20) => {
    return new Promise(async (resolve, reject) => {
        try {
            //send inquiry to sockt for ended sessions
            const mode = await promiseGetRecoil(environmentState)
            const token = localStorage.getItem('auth_token')
            event_server.emit(
                'session',
                {
                    status: 'ended',
                    mode,
                    token,
                    data: { offset: offset, limit: limit },
                },
                async result => {
                    consolewrite('sended successfully')
                    consolewrite(result)
                    const { data } = JSON.parse(result)
                    const sessions = await promiseGetRecoil(sessionState)
                    if (data?.length > 0) {
                        const updatedList = removeDuplicateArr(
                            [...sessions.ended, ...data],
                            (t, v) => t.Id === v.Id
                        )
                        await promiseSetRecoil(sessionState, {
                            ...sessions,
                            ended: updatedList,
                            loading: false,
                        })
                    } else {
                        await promiseSetRecoil(sessionState, {
                            ...sessions,
                            loading: false,
                        })
                    }
                    resolve(data)
                }
            )
        } catch (error) {
            reject(false)
        }
    })
}
export const requestUsersSession = (sessionId, offset = 0, limit = 10, query="") => {
  return new Promise(async (resolve, reject) => {
    try {
      consolewrite('Load user session...')
      const mode = await promiseGetRecoil(environmentState)
      const token = localStorage.getItem('auth_token')
      //send inquiry to socket for inprogress sessions
      event_server.emit(
        'session',
        {
          status: 'users',
          mode,
          token,
          data: {
            session: sessionId,
            offset: offset,
            limit: limit,
            query: query
          },
        },
        result => {
          //consolewrite('users session result', result)
          const { data } = JSON.parse(result)
          resolve(data)
        }
      )
    } catch (error) {
      reject(false)
    }
  })
}

export const requestAddUpcomingSession = awardAmount => {
    return new Promise(async (resolve, reject) => {
        try {
            const mode = await promiseGetRecoil(environmentState)
            const token = localStorage.getItem('auth_token')
            event_server.emit(
                'session',
                {
                    status: 'add-upcoming',
                    mode,
                    data: {
                        awardamount: awardAmount,
                        mode,
                        token,
                    },
                },
                async result => {
                    console.log('requested add upcoming session')
                    console.log(result)
                    result = JSON.parse(result)
                    resolve(result.data)
                }
            )
        } catch (error) {
            reject(error)
        }
    })
}
export const requestChangeAwardAmount = async (sessionId, awardAmount = 0) => {
    return new Promise(async (resolve, reject) => {
        try {
            const mode = await promiseGetRecoil(environmentState)
            const token = localStorage.getItem('auth_token')
            event_server.emit(
                'session',
                {
                    status: 'edit-session',
                    mode,
                    data: {
                        session: sessionId,
                        awardamount: awardAmount,
                        mode,
                    },
                    token
                },
                async result => {
                    console.log('requested change award amout')
                    console.log(result)
                    result = JSON.parse(result)
                    resolve(result.data)
                }
            )
        } catch (error) {
            reject(error)
        }
    })
}
export const requestResendSessionRewards = async (sessionId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const mode = await promiseGetRecoil(environmentState)
            const token = localStorage.getItem('auth_token')
            event_server.emit(
                'session',
                {
                    status: 'resend-session-rewards',
                    mode,
                    data: {
                        sessionId: sessionId,
                    },
                    token
                },
                async result => {
                    console.log('requested session resend')
                    console.log(result)
                    result = JSON.parse(result)
                    resolve(result)
                }
            )
        } catch (error) {
            reject(error)
        }
    })  
}