import { HStack, VStack } from '@chakra-ui/layout'
import {
  Button,
  Center,
  Text,
  IconButton,
  Divider,
  useDisclosure,
  Heading,
  useToast,
} from '@chakra-ui/react'
import { FaClipboard } from 'react-icons/fa'
import TransferModal from 'components/pages/modals/transfer'
import { copyToClipBoard } from 'utils'
const QRCode = require('qrcode.react')
export default function WalletHeader({ wallet }) {
  const {
    isOpen: isTransferModalOpen,
    onOpen: onOpenTransferModal,
    onClose: onCloseTransferModal,
  } = useDisclosure()
  const toast = useToast()
  const handleCopyToClipboard = () => {
    copyToClipBoard(wallet.Address)
    toast({
      title: 'copied',
      status: 'success',
      duration: 2000,
      isClosable: true,
      position: 'top',
    })
  }
  return (
    <HStack w="full" h="full" spacing={10} justify="space-around">
      <VStack w="full" h="full" spacing={4}>
        <Heading>{wallet.Balance}</Heading>
        <Text>ELA</Text>
        <Button onClick={onOpenTransferModal}>Transfer funds</Button>
        <TransferModal
          heading={'Transfer your funds to this address'}
          isOpen={isTransferModalOpen}
          onClose={onCloseTransferModal}
        />
      </VStack>
      <Center height="20vh">
        <Divider orientation="vertical" />
      </Center>
      <VStack w="full" h="full" spacing={4}>
        <QRCode includeMargin value={wallet.Address} />
        <HStack>
          <Text>{wallet.Address}</Text>
          <IconButton
            variant="ghost"
            icon={<FaClipboard />}
            onClick={handleCopyToClipboard}
          />
        </HStack>
      </VStack>
    </HStack>
  )
}
