
import { sendEla } from 'api/backend'
import { promiseSetRecoil, promiseGetRecoil } from 'recoil-outside'
import { event_server } from 'Socket'
import {
    environmentState,
    walletState,
} from 'state/atom'

export const requestWallet = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const mode = await promiseGetRecoil(environmentState)
            const token = localStorage.getItem('auth_token')
            event_server.emit(
                'wallet',
                { status: 'load', mode, token },
                async result => {
                    const wallet = await promiseGetRecoil(walletState)
                    const { data } = JSON.parse(result)
                    resolve({
                        ...wallet,
                        Address: data.Address,
                        Balance: data.Balance,
                        Transactions: data.Transactions,
                    })
                }
            )
        } catch (error) {
            reject(false)
        }
    })
}

export const requestSendEla = async addressInfo => {
    return await sendEla(addressInfo)
}

export const requestBalance = async offset => {
    const mode = await promiseGetRecoil(environmentState)
    const token = localStorage.getItem('auth_token')
    event_server.emit(
        'wallet',
        { status: 'balance', mode, token },
        async result => {
            console.log('Requesting balance... ')
            console.log(result)
            // @bonhokage ADD LOGIC BELOW
        }
    )
}

export const requestWalletTransactions = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const mode = await promiseGetRecoil(environmentState)
            const token = localStorage.getItem('auth_token')
            event_server.emit(
                'wallet',
                { status: 'get-all-transactions', mode, token },
                async result => {
                    const wallet = await promiseGetRecoil(walletState)
                    const { data } = JSON.parse(result)
                    if (data?.length > 0) {
                        await promiseSetRecoil(walletState, {
                            ...wallet,
                            transactions: data.transactions,
                        })
                    }
                    resolve(true)
                }
            )
        } catch (error) {
            reject(false)
        }
    })
}