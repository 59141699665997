import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'
import { useRecoilState } from 'recoil'
import { settingsState } from 'state/atom'
import { requestSettings, requestUpdateSettings } from 'state/actions/config'
export default function useSettings() {
  const toast = useToast()
  const [settings, setSettings] = useRecoilState(settingsState)
  const updateSettings = async updatedSettings => {
    try {
      await requestUpdateSettings(updatedSettings)
      setSettings(updatedSettings)
      toast({
        title: 'Settings updated',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })
    } catch (error) {
      toast({
        title: 'Settings not updated',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })
    } finally {
      await fetchSettings()
    }
  }
  const fetchSettings = async () => {
    setSettings({ ...settings, loading: true })
    const updatedSettings = await requestSettings()
    setSettings({ ...updatedSettings, loading: false })
  }
  useEffect(() => {
    fetchSettings()
    // eslint-disable-next-line
  }, [setSettings])
  return { toast, settings, setSettings, updateSettings }
}
