import { VStack } from '@chakra-ui/layout'
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  IconButton,
} from '@chakra-ui/react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { useColorModeValue } from '@chakra-ui/color-mode'
import { Button } from '@chakra-ui/button'
import { ChevronDownIcon, UnlockIcon } from '@chakra-ui/icons'
import Rewards from 'components/pages/rewards'
import { authenticatedState, environmentState } from 'state/atom'
import { reloadPage } from 'utils'
import Wallet from './wallet'
import Users from "./users"
import License from './license'
const outlineC = '#0d89fe'
export default function Dashboard() {
  const [environment, setEnvironment] = useRecoilState(environmentState)
  const setAuthState = useSetRecoilState(authenticatedState)
  const tabListColor = useColorModeValue('white', 'gray.800')
  const menuItemOnChange = selectedItem => {
    setEnvironment(selectedItem)
    reloadPage()
  }
  const handleLogout = () => {
    localStorage.removeItem('auth_token')
    setAuthState({ isAuthenticated: false })
  }
  const selectedTab = { outlineColor: outlineC, color: outlineC }
  return (
    <VStack w="full" height="full" p={3} alignItems="flex-start">
      <Tabs
        colorScheme="none"
        w="full"
        h="full"
        variant="soft-rounded"
        size="lg"
        isFitted
        isLazy
      >
        <TabList
          bg={tabListColor}
          p={2}
          style={{ position: 'sticky', top: 0, zIndex: 100 }}
        >
          <Tab _selected={selectedTab}>Rewards</Tab>
          <Tab _selected={selectedTab}>License</Tab>
          <Tab _selected={selectedTab}>Users</Tab>
          <Tab _selected={selectedTab}>Wallet</Tab>
          <Menu w="full">
            <MenuButton
              data-testid="environment-button"
              mt={2}
              ml={2}
              as={Button}
              rightIcon={<ChevronDownIcon />}
            >
              {environment.toUpperCase()}
            </MenuButton>
            <MenuList>
              <MenuOptionGroup
                defaultValue={environment}
                title="Environment"
                type="radio"
                onChange={menuItemOnChange}
              >
                <MenuItemOption value="debug">Debug</MenuItemOption>
                <MenuItemOption
                  data-testid="environment-staging-btn"
                  value="staging"
                >
                  Staging
                </MenuItemOption>
                <MenuItemOption
                  data-testid="environment-release-btn"
                  value="release"
                >
                  Release
                </MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </Menu>
          <IconButton
            mt={2}
            ml={2}
            aria-label="Logout"
            icon={<UnlockIcon />}
            onClick={handleLogout}
          />
        </TabList>

        <TabPanels>
          <TabPanel>
            <Rewards />
          </TabPanel>
          <TabPanel>
            <License />
          </TabPanel>
          <TabPanel>
            <Users/>
          </TabPanel>          
          <TabPanel>
            <Wallet />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  )
}
