import axios from 'axios'
import { SERVER_URL } from 'config'
const headers = () => {
  const token = localStorage.getItem('auth_token')
  return { headers: { Authorization: `Bearer ${token}` } }
}
export const getSettings = async () => {
  const { data: result } = await axios.get(
    `${SERVER_URL}/apiv1/rewards/settings`,
    headers()
  )
  return result.data
}
export const sendEla = async addressInfo => {
  const { data: result } = await axios.post(
    `${SERVER_URL}/apiv1/rewards/sendEla`,
    addressInfo,
    headers()
  )
  return result
}

export const auth = async creds => {
  const { data: result } = await axios.post(
    `${SERVER_URL}/apiv1/rewards/auth`,
    creds
  )
  return result.data
}

export const checkToken = async () => {
  const { data: result } = await axios.post(
    `${SERVER_URL}/apiv1/rewards/auth/verify`,
    null,
    headers()
  )
  return result.data
}
