import { VStack } from '@chakra-ui/layout'
import { Accordion, Spinner } from '@chakra-ui/react'
import RewardsList from 'components/pages/rewards/list'
import Controller from 'components/pages/rewards/controller'
import { format } from 'date-fns'
import useSession from 'hooks/useSession'
export default function History() {
  const { sessions, sessionStatus, 
    firstHistoryLoading, 
    secondHistoryLoading, 
    isSendingRewards , 
    resendRewards,
    updateSessionStatus } =
    useSession(true)
  if (firstHistoryLoading) {
    return (
      <Controller heading="History">
        <VStack w="full" h="55vh" alignItems="center" justify="center">
          <Spinner size="xl" />
        </VStack>
      </Controller>
    )
  }
  return (
    <Controller heading="History">
      <Accordion w="full">
        {sessions.ended?.map((Session, index) => {
          const hasError = Session.Status !== 2 && Session.Status !== 8
          const statusMessage = sessionStatus[Session.Status].message
          const status = `${format(
            new Date(Session.StartAt * 1000),
            'M/dd/yyyy hh:mm:ss a'
          )} - ${format(
            new Date(Session.EndAt * 1000),
            'M/dd/yyyy hh:mm:ss a'
          )}`
          return (
            <RewardsList
              key={index}
              sessionStatus={sessionStatus}
              status={status}
              heading={statusMessage}
              hasStatusBox
              hasError
              statusBoxColor={`${hasError ? 'red' : 'green'}`}
              isSendingRewards={isSendingRewards}              
              isRewardEditable={false}
              session={Session}
              updateSessionStatus={updateSessionStatus}
              resendRewards={resendRewards}
            />
          )
        })}
      </Accordion>
      {secondHistoryLoading && <Spinner size="lg" />}
    </Controller>
  )
}
