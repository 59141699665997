import React, { useState } from 'react'
import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogBody,
  Input,
  AlertDialogFooter,
  Button,
  Spinner,
  Text,
} from '@chakra-ui/react'

const SessionDialog = ({ isOpen, onClose, onAdded = awardAmount => {} }) => {
  const [isAdding, setIsAdding] = useState(false)
  const [reward, setReward] = useState('')
  const cancelRef = React.useRef()
  const handleChanged = event => setReward(event.target.value)
  const handleAdd = async () => {
    try {
      setIsAdding(true)
      await onAdded(parseFloat(reward))
      onClose()
    } catch (error) {
      setIsAdding(false)
    }
  }
  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>Add Upcoming Session</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Text>ELA Rewarded</Text>
          <Input value={reward} onChange={handleChanged} type="number" />
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button colorScheme="green" onClick={handleAdd} disabled={isAdding}>
            {isAdding ? <Spinner size="xs" /> : 'Add'}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default SessionDialog
