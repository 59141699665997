import { useState } from 'react'
import { FormControl, FormLabel } from '@chakra-ui/form-control'
import { GridItem, SimpleGrid, VStack } from '@chakra-ui/layout'
import {
  Spinner,
  Select,
  Switch,
  Button,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import Controller from 'components/pages/rewards/controller'
import { useEffect } from 'react'
import useSettings from 'hooks/useSettings'
import { intervalCodeToString } from 'utils'
export default function Settings() {
  const [isEditting, setIsEditting] = useState(false)
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm()
  const { settings, updateSettings } = useSettings()
  useEffect(() => {
    const updateFormValues = () => {
      const updatedSettings = {
        ...settings,
        'default-reward': '' + settings['default-reward'],
        'transfer-fee': '' + settings['transfer-fee'],
      }
      setValue('interval', updatedSettings['interval'])
      setValue('default-reward', updatedSettings['default-reward'])
      setValue('transfer-fee', updatedSettings['transfer-fee'])
      setValue('enabled', updatedSettings['enabled'])
      // setIsEnabled(settings['enabled'])
    }
    updateFormValues()
  }, [settings, setValue, isEditting])
  const handleEditting = e => {
    e.preventDefault()
    setIsEditting(!isEditting)
  }
  const handleOnSubmit = async settings => {
    const updatedSettings = {
      ...settings,
      'default-reward': parseFloat(settings['default-reward']),
      'transfer-fee': parseFloat(settings['transfer-fee']),
    }
    await updateSettings(updatedSettings)
  }
  if (settings.loading) {
    return (
      <Controller heading="Settings">
        <VStack w="full" h="55vh" alignItems="center" justify="center">
          <Spinner size="xl" />
        </VStack>
      </Controller>
    )
  }
  return (
    <Controller heading="Settings">
      <form onSubmit={handleSubmit(handleOnSubmit)} style={{ width: '100%' }}>
        <SimpleGrid w="full" h="full" rowGap="3">
          <GridItem>
            <FormControl>
              {isEditting ? (
                <>
                  <FormLabel isRequired>Interval</FormLabel>
                  <Select
                    id="interval"
                    placeholder="Select Interval"
                    {...register('interval')}
                  >
                    <option value="@every 15m">15 mins</option>
                    <option value="@every 1h">1 hour</option>
                    <option value="@every 2h">2 hours</option>
                    <option value="@every 4h">4 hours</option>
                    <option value="@every 24h">24 hours</option>
                    <option value="@daily">Daily</option>
                    <option value={`${settings['interval']==="@weekly" ? "@weekly":"@every 1w"}`}>Weekly</option>
                    <option value="@monthly">Monthly</option>
                  </Select>
                </>
              ) : (
                <Text>
                  Interval: {intervalCodeToString(settings['interval'])}
                </Text>
              )}
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isRequired isInvalid={errors.deafult_reward}>
              {isEditting ? (
                <>
                  <FormLabel>Default Reward</FormLabel>
                  <NumberInput min={0} step={0.01}>
                    <NumberInputField
                      id="default-reward"
                      {...register('default-reward', {
                        required: 'This is required',
                      })}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </>
              ) : (
                <Text>Default Reward: {settings['default-reward']}</Text>
              )}
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isRequired isInvalid={errors.transfer_fee}>
              {isEditting ? (
                <>
                  <FormLabel>Transfer Fee</FormLabel>
                  <NumberInput min={0} step={0.001}>
                    <NumberInputField
                      id="transfer-fee"
                      {...register('transfer-fee', {
                        required: 'This is required',
                      })}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </>
              ) : (
                <Text>Transfer Fee: {settings['transfer-fee']}</Text>
              )}
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isRequired>
              <Switch
                id="enabled"
                mr="1"
                isDisabled={!isEditting}
                defaultChecked={settings['enabled']}
                {...register('enabled')}
              />{' '}
              Enable
            </FormControl>
          </GridItem>
          {isEditting ? (
            <>
              <GridItem>
                <Button
                  type="submit"
                  mb={5}
                  isFullWidth
                  colorScheme="brand"
                  isLoading={isSubmitting}
                >
                  Update
                </Button>
                <Button
                  type="button"
                  isFullWidth
                  onClick={handleEditting}
                  disabled={isSubmitting}
                >
                  Close
                </Button>
              </GridItem>
            </>
          ) : (
            <GridItem>
              <Button type="button" isFullWidth onClick={handleEditting}>
                Edit Settings
              </Button>
            </GridItem>
          )}
        </SimpleGrid>
      </form>
    </Controller>
  )
}
