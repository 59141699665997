import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { licensesState, generatedLicenseState } from 'state/atom'
import {
  requestLicenses,
  requestDeleteLicense,
  requestAddLicense,
  requestUpdateLicense,
} from 'state/actions/license'
import useScroll from 'hooks/useScroll'
export default function useLicenses() {
  const [licenses, setLicenses] = useRecoilState(licensesState)
  const [generatedLicense, setGeneratedLicense] = useRecoilState(
    generatedLicenseState
  )
  const { offset, setOffset } = useScroll()
  const firstLoading = licenses?.loading && licenses?.list?.length === 0
  const secondLoading = licenses?.loading && licenses.list.length > 0
  const generateLicense = async data => {
    const generatedLicense = await requestAddLicense(
      data['license-type'].toUpperCase(),
      data['username']
    )
    setGeneratedLicense(generatedLicense.Id)
  }
  const deleteLicense = async id => {
    await requestDeleteLicense(id)
    const updatedList = licenses.list.filter(license => license.Id !== id)
    setLicenses({ ...licenses, list: updatedList })
  }
  const searchLicenses = async query => {
    setLicenses({ ...licenses, list: [], loading: true, query })
    await requestLicenses(0, query)
    setOffset(0)
  }
  const updateLicense = async (id, owner, device) => {
    await requestUpdateLicense(id, owner, device)
    const i = licenses.list.findIndex( (val) => val.Id === id)
    const nlist = [...licenses.list]
    nlist[i] = {...nlist[i], Owner: owner, Serial:device};
    setLicenses({...licenses, list: nlist})
  }
  useEffect(() => {
    const handleRequestLicenses = async () => {
      setLicenses({ ...licenses, loading: true })
      await requestLicenses(offset, licenses.query)
    }
    handleRequestLicenses()
    // eslint-disable-next-line
  }, [offset])
  return {
    licenses,
    setLicenses,
    generatedLicense,
    generateLicense,
    searchLicenses,
    updateLicense,
    deleteLicense,
    firstLoading,
    secondLoading,
  }
}
