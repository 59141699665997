import RewardsList from 'components/pages/rewards/list'
import { formatDistance } from 'date-fns'
export default function InProgress({ sessions, sessionStatus, updateSession }) {
    const Session = sessions.inProgress
    const endAt = new Date(Session.EndAt * 1000)
    const heading = `${formatDistance(endAt, Date.now())} Left`
    const status = `IN PROGRESS`
    return (
        <RewardsList
            status={status}
            heading={heading.toUpperCase()}
            hasStatusBox
            statusBoxColor="green"
            session={Session}
            sessionStatus={sessionStatus}
            updateSession={updateSession}
        />
    )
}
