import { promiseSetRecoil, promiseGetRecoil } from 'recoil-outside'
import { event_server } from 'Socket'
import { auth, checkToken } from 'api/backend'
import { devicesState, environmentState } from 'state/atom'
import { removeDuplicateArr } from 'utils'

export const requestDevices = (offset, query = '') => {
  return new Promise(async (resolve, reject) => {
    try {
      // send inquiry to sockt for users
      const mode = await promiseGetRecoil(environmentState)
      const token = localStorage.getItem('auth_token')
      event_server.emit(
        'devices',
        { status: 'get-data', mode, token, offset, query },
        async result => {
          console.log(' user sended successfully')
          result = JSON.parse(result)
          const { data } = result
          const devices = await promiseGetRecoil(devicesState)
          if (data?.length > 0) {
            const updatedList = removeDuplicateArr(
              [...devices.list, ...data],
              (t, v) => t.Serial === v.Serial
            )
            await promiseSetRecoil(devicesState, {
              ...devices,
              list: updatedList,
              loading: false,
              devicesLoading: false,
            })
          } else {
            await promiseSetRecoil(devicesState, {
              ...devices,
              loading: false,
              devicesLoading: false,
            })
          }
          resolve(true)
        }
      )
    } catch (error) {
      reject(error)
    }
  })
}

export const requestAuth = async creds => {
  return await auth(creds)
}

export const requestCheckToken = async () => {
  const token = localStorage.getItem('auth_token')
  if (token?.length > 0) {
    return await checkToken()
  }
  return {
    data: {
      authorized: false,
      exp: '',
      username: '',
    },
  }
}
