
import { VStack,HStack} from '@chakra-ui/layout'
import { Input, Accordion, IconButton, Spinner,Heading } from '@chakra-ui/react'
import { FiFilter } from 'react-icons/fi'
import UsersList from 'components/pages/users/list'
import useUsers from 'hooks/useUser'


export default function Users(){
    const {
        users,
        pageLoading,
        userLoading,
        fetchUsers,
        query,
        setQuery,
      } = useUsers()
      const handleQueryChange = e => {
        setQuery(e.target.value.trim())
      }
      const handleKeyPressed = e => {
        if (e.key === 'Enter') {
            fetchUsers(true, query)
        }
      }    
      if (pageLoading) {
        return (
          <VStack w="full" h="100vh" alignItems="center" justify="center">
            <Spinner size="xl" />
          </VStack>
        )
      }      
    return (
        <VStack w="full" h="full" spacing={5} justify="center">
            <Heading>Users</Heading>            
            <HStack>
            <p>Users</p>
            <Input
                placeholder="Search"
                onChange={handleQueryChange}
                onKeyPress={handleKeyPressed}
            />
            <IconButton size="sm" icon={<FiFilter />} />
            </HStack>
            <Accordion w="full">
            {users.list.map(user => {
                const heading = `${user.Id} - ${user.Username}`
                return <UsersList key={user.Id} heading={heading} user={user} />                
            })}
            </Accordion>
            {userLoading && <Spinner size="lg" />}
        </VStack>
    )
}