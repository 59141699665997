import { HStack } from '@chakra-ui/layout'
import { Tabs, TabList, TabPanels, Tab, TabPanel, Icon } from '@chakra-ui/react'
import { FaCogs, FaHistory } from 'react-icons/fa'
import {BiDevices} from "react-icons/bi"
import { AiFillDashboard } from 'react-icons/ai'
import RewardsDashboard from 'components/pages/rewards/dashboard'
import RewardsHistory from 'components/pages/rewards/history'
import RewardsSettings from 'components/pages/rewards/settings'
import RewardsDevices from 'components/pages/rewards/devices'
export default function Rewards() {
  return (
    <HStack w="full" h="full" p={10}>
      <Tabs
        colorScheme="brand"
        w="full"
        h="full"
        variant="soft-rounded"
        align="start"
        orientation="vertical"
        isLazy
      >
        <TabList marginTop={5} h="30" style={{ position: 'sticky', top: 100 }}>
          <Tab my={2}>
            <Icon as={AiFillDashboard} marginRight={2} />
            Dashboard
          </Tab>
          <Tab my={2}>
            <Icon as={FaHistory} marginRight={2} />
            History
          </Tab>
          <Tab my={2}>
            <Icon as={FaCogs} marginRight={2} />
            Settings
          </Tab>
          <Tab my={2}>
            <Icon as={BiDevices} marginRight={2} />
            Devices
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <RewardsDashboard />
          </TabPanel>
          <TabPanel>
            <RewardsHistory />
          </TabPanel>
          <TabPanel>
            <RewardsSettings />
          </TabPanel>
          <TabPanel>
            <RewardsDevices />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </HStack>
  )
}
