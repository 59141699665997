import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { Button, HStack, Spacer, useDisclosure, VStack } from '@chakra-ui/react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  IconButton,
} from '@chakra-ui/react'
import ConfirmationDialog from 'components/alertdialogs/confirmation'
import { InputX } from 'components/forms/input'
import { format } from 'date-fns'
import { useState } from 'react'

function LicenseCard({license, onDelete, onApply}) {
  const isAvailableLicenseLabel = license.IsAvailable
            ? 'Available'
            : 'Taken'
  const [edit,setEdit] = useState(false);
  const [updated,setUpdated] = useState(license);
  const [dirty,setDirty] = useState(false)
  const setValue = (caption,value) => {
    var newVal = {};
    if (updated !== null) newVal = {...updated};
    newVal[caption] = value;
    setUpdated(newVal);
    setDirty(true)
  }
  const onCancelApply = () => {
    setUpdated(license)
    setDirty(false)
  }
  const {
    isOpen: isDialogOpen,
    onOpen: onOpenDialog,
    onClose: onCloseDialog,
  } = useDisclosure()
  return (
    <>
    <ConfirmationDialog
      header="Update License"
      body="Do you want to save changes?"
      isOpen={isDialogOpen}
      onClose={onCloseDialog}
      confirmLabel="Save"
      onConfirm={() => {
          onApply(updated)
          onCloseDialog()
          setDirty(false)
          setEdit(false)
      }}
    />
    <AccordionItem key={license.Id}>
              <h2>
                <AccordionButton>
                  <HStack w="full" justifyContent={'flex-start'} spacing={10}>
                    <p>{license.Id}</p>
                    <p>{license.Type}</p>
                    <p>{isAvailableLicenseLabel}</p>
                  </HStack>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <VStack>
                  <p>License #: {license.Id}</p>
                  <p>Type: {license.Type}</p>
                  <InputX 
                    caption='Owner:' 
                    value={updated.Owner} 
                    edit={edit}
                    onChange={(event) => setValue('Owner', event.target.value)}/>
                  {isAvailableLicenseLabel && <InputX 
                    caption="Owner's Device:"
                    value={updated.Serial} 
                    edit={edit}
                    onChange={(event) => setValue('Serial', event.target.value)}/>}
                  <p>Status: {isAvailableLicenseLabel}</p>
                  {license.CreatedAt?.length > 0 && (
                    <p>
                      Created at:{' '}
                      {format(
                        new Date(license.CreatedAt),
                        'M-dd-yyyy hh:mm:ss a X'
                      )}
                    </p>
                  )}
                </VStack>
                <HStack w="full" justify="flex-end">
                  {edit && <IconButton
                      aria-label="Delete License"
                      colorScheme="red"
                      icon={<DeleteIcon />}
                      onClick={() => {
                        onDelete(license.Id)
                      }}
                    />}
                  <Spacer />
                  { (!dirty || !edit) && <IconButton aria-label="Edit License" 
                    icon={<EditIcon />}
                    onClick={() => {
                      setEdit(!edit)
                    }}/>
                  }
                  { dirty && <>
                    <Button onClick={onCancelApply}>Cancel</Button>
                    <Button onClick={() => onOpenDialog()}>Apply</Button>
                  </>}
                </HStack>
              </AccordionPanel>
            </AccordionItem>
      </>
  )
}

export default function LicenseList({ licenses, handleDeleteLicense, handleUpdateLicense }) {
  return (
    <>
    
    <VStack w="full" h="full">
      <Accordion w="full" mb={10}>
        {licenses?.list?.map(license => {
          return (<LicenseCard license={license} 
            onDelete={handleDeleteLicense}
            onApply={handleUpdateLicense}/>)
        })}
      </Accordion>
    </VStack>
    </>
  )
}
